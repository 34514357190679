import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Typography, Button, Paper, Grid } from '@mui/material'
import { DeleteRounded, EditRounded } from '@mui/icons-material'

import Table, { Row, TableProps } from '@objects/table'
import { IAction } from '@objects/table/tableRow'
import useSnackbar from '@hooks/useSnackbar'
import useDialog from '@hooks/useDialog'
import LoadingSkeleton from '@objects/loadingSkeleton'
import {
  IFeedbackNotificationRecipient,
  deleteFeedbackNotificationRecipients,
  getFeedbackNotificationRecipients,
} from '@services/feedbackuserservice'

const headCells = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'E-Mail Adresse' },
  { id: 'actions', label: 'Aktionen' },
]

function createData(recipient: IFeedbackNotificationRecipient): Row {
  return {
    id: recipient.id,
    items: [{ label: recipient.name }, { label: recipient.email }],
    hideActions: false,
  }
}

export default function DownloadNotificationPage() {
  const [recipients, setRecipients] =
    useState<Array<IFeedbackNotificationRecipient>>()
  const [rowCells, setRowCells] = useState<TableProps['rows']>()
  const [loading, setLoading] = useState(true)
  const { toggleSnackbar } = useSnackbar()
  const { toggle } = useDialog()
  const intl = useIntl()

  const actions: Array<IAction> = [
    {
      title: 'Bearbeiten',
      icon: <EditRounded />,
      onClick: (id) => {
        const rec = recipients?.find((r) => r.id === id)
        if (!rec) return
        toggle(
          'editrecipient',
          () => {
            updateRecipients()
            toggleSnackbar(
              intl.formatMessage({ id: 'snackbar.success' }),
              'success'
            )
          },
          {
            variant: 'feedback',
            recipientid: id,
            name: rec.name,
            email: rec.email,
          }
        )
      },
    },
    {
      title: 'Löschen',
      icon: <DeleteRounded />,
      onClick: (id) => {
        toggle('areyousure', () =>
          deleteFeedbackNotificationRecipients(id).then(() => {
            updateRecipients()
            toggleSnackbar(
              intl.formatMessage({ id: 'snackbar.success' }),
              'success'
            )
          })
        )
      },
    },
  ]

  function updateRecipients() {
    setLoading(true)
    getFeedbackNotificationRecipients().then((data) => {
      setRecipients(data)
      setRowCells(data.map((rec) => createData(rec)))
      setLoading(false)
    })
  }

  useEffect(() => {
    updateRecipients()
  }, [])

  return (
    <React.Fragment>
      <Typography>
        <FormattedMessage id="feedback.notification.recipients.introtext" />
      </Typography>
      <LoadingSkeleton type="table" loading={loading} number={1}>
        <React.Fragment>
          <Paper
            sx={{
              p: 4,
              mt: 4,
              mb: 12,
            }}
          >
            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    toggle('addrecipient', () => updateRecipients(), {
                      variant: 'feedbackNotification',
                    })
                  }}
                >
                  <FormattedMessage id="button.addrecipient" />
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {rowCells?.length ? (
            <Table heads={headCells} rows={rowCells} actions={actions} />
          ) : (
            <Typography>
              <FormattedMessage id="feedback.notification.recipients.nodatayet" />
            </Typography>
          )}
        </React.Fragment>
      </LoadingSkeleton>
    </React.Fragment>
  )
}
